@import url(https://fonts.googleapis.com/css2?family=Leckerli+One&family=Roboto+Mono&display=swap);
.about__me {
  padding-top: 1em;
  white-space: pre-wrap;
}

.about__photo {
  border-radius: 100%;
  box-shadow: '2px 2px 4px rgba(0, 0, 0, 0.5)';
  width: 100%;
  max-width: 25em;
}

.about__photoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__griffin {
  max-width: 10em;
}

.about__griffinContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__technologies {
  margin-top: 4em;
}

.about__technology {
  display: flex;
  justify-content: center;
}

.about__technology-img {
  width: 100%;
  max-width: 5em;
  max-height: 5em;
}

@media only screen and (max-width: 600px) {
  .about__photoContainer {
    display: inline;
    display: initial;
  }

  .about__griffinContainer {
    display: inline;
    display: initial;
  }
}
.header {
  background: url(/static/media/feathers.b7fa330c.jpg) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  box-shadow: 2px 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.header__contents {
  height: calc(100% - 4em);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15%;
}

@media only screen and (max-width: 600px) {
  .header {
    background-position: 0 0;
    background-position: initial;
    background-size: auto 100vh;
  }
}

.projects__container {
  margin: 4em 0;
}

.projects__project {
  display: flex;
  justify-content: center;
}

.projects__project-box {
  border-radius: 4px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #323232;
  padding-bottom: 0.5em;
  transition: all 0.2s;
}

.projects__project-box:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.projects__project-img {
  max-width: 100%;
  max-height: 320px;
  border-radius: 4px 4px 0 0;
}

@media only screen and (max-width: 600px) {
  .projects__project {
    display: inline;
    display: initial;
    justify-content: initial;
  }
}

.projectDialog__container {
  background: repeating-linear-gradient(45deg, #323232, #323232 10px, #353535 10px, #353535 20px);
  height: 100%;
  overflow-y: auto;
}

.projectDialog__top {
  display: flex;
  justify-content: space-between;
}

.projectDialog__controls {
  display: flex;
  padding: 0.5em 0.5em 0 0;
}

.projectDialog__img-container {
  padding: 2em;
}

.projectDialog__img-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectDialog__img-carousel-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projectDialog__img {
  max-width: 80%;
  max-height: 50vh;
  margin: 1em;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.projectDialog__img-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectDialog__mobile-buttons {
  display: flex;
  align-items: center;
}

.projectDialog__description {
  padding: 0 3em 1em 3em;
}

@media only screen and (max-width: 600px) {
  .projectDialog__img-container {
    padding: 0;
  }

  .projectDialog__description {
    padding: 0;
  }
}

.section {
  padding: 0 3em 3em 3em;
}

.topNavBar__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.topNavBar__menuItems {
  display: flex;
}

.topNavBar__button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin: 0 0.5em;
}


.app {
  color: #fff;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(45deg, #323232, #323232 10px, #353535 10px, #353535 20px);
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

