.projectDialog__container {
  background: repeating-linear-gradient(45deg, #323232, #323232 10px, #353535 10px, #353535 20px);
  height: 100%;
  overflow-y: auto;
}

.projectDialog__top {
  display: flex;
  justify-content: space-between;
}

.projectDialog__controls {
  display: flex;
  padding: 0.5em 0.5em 0 0;
}

.projectDialog__img-container {
  padding: 2em;
}

.projectDialog__img-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.projectDialog__img-carousel-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projectDialog__img {
  max-width: 80%;
  max-height: 50vh;
  margin: 1em;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.projectDialog__img-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectDialog__mobile-buttons {
  display: flex;
  align-items: center;
}

.projectDialog__description {
  padding: 0 3em 1em 3em;
}

@media only screen and (max-width: 600px) {
  .projectDialog__img-container {
    padding: 0;
  }

  .projectDialog__description {
    padding: 0;
  }
}
