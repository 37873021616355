.topNavBar__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.topNavBar__menuItems {
  display: flex;
}

.topNavBar__button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin: 0 0.5em;
}