.header {
  background: url(../assets/feathers.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  box-shadow: 2px 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.header__contents {
  height: calc(100% - 4em);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15%;
}

@media only screen and (max-width: 600px) {
  .header {
    background-position: initial;
    background-size: auto 100vh;
  }
}
