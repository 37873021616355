.projects__container {
  margin: 4em 0;
}

.projects__project {
  display: flex;
  justify-content: center;
}

.projects__project-box {
  border-radius: 4px;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: #323232;
  padding-bottom: 0.5em;
  transition: all 0.2s;
}

.projects__project-box:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.projects__project-img {
  max-width: 100%;
  max-height: 320px;
  border-radius: 4px 4px 0 0;
}

@media only screen and (max-width: 600px) {
  .projects__project {
    display: initial;
    justify-content: initial;
  }
}
