.about__me {
  padding-top: 1em;
  white-space: pre-wrap;
}

.about__photo {
  border-radius: 100%;
  box-shadow: '2px 2px 4px rgba(0, 0, 0, 0.5)';
  width: 100%;
  max-width: 25em;
}

.about__photoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__griffin {
  max-width: 10em;
}

.about__griffinContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about__technologies {
  margin-top: 4em;
}

.about__technology {
  display: flex;
  justify-content: center;
}

.about__technology-img {
  width: 100%;
  max-width: 5em;
  max-height: 5em;
}

@media only screen and (max-width: 600px) {
  .about__photoContainer {
    display: initial;
  }

  .about__griffinContainer {
    display: initial;
  }
}